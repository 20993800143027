import React from 'react';

import { Controller } from 'react-hook-form';

import {
  TextField,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Box,
  CircularProgress,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useModel } from './model';
import { ImagesUpload } from 'features/Image';
import { imageTypeAlias } from 'shared/constants';
import { capitalizeFirstLetter } from 'shared/helper';
import TextFieldSearch from 'features/Location/TextFieldSearch';
import { Modal } from 'shared/ui/Modal';
import { PickCategory } from '../PickCategory';
import theme from 'shared/theme';
import { Advert } from 'services/interfaces/Advert';
import { Company } from 'services/interfaces/Company';
import { Measure } from 'services/interfaces/Measure';
import { renderItem } from 'shared/helper/renderItem';
import { CatalogCategory } from 'types/types';
import { Category } from 'services/interfaces/Category';
import { MachineRoom } from 'services/interfaces/MachineRoom';
import { CarryingCapacity } from 'services/interfaces/CarryingCapacity';
import { LiftingSpeed } from 'services/interfaces/LiftingSpeed';

export const UI = ({
  company,
  category,
  measureUnit,
  machineRoom,
  carryingCapacity,
  liftingSpeed,
  advert,
  catalogCategories,
}: {
  company: Company[];
  category: Category;
  measureUnit: Measure[];
  machineRoom: MachineRoom[];
  carryingCapacity: CarryingCapacity[];
  liftingSpeed: LiftingSpeed[];
  advert?: Advert;
  catalogCategories?: CatalogCategory[];
}) => {
  const {
    handleSubmit,
    onSubmit,
    control,
    errors,
    setSelectedCategory,
    selectedCategory,
    selectedCategoryName,
    companyData,
    setSelectedCompany,
    measure,
    imagesUploadRef,
    loading,
    shouldRenderController,
    carryingCapacities,
    liftingSpeeds,
    machineRooms,
    categories,
    setSelectedCatalogId,
    myCompanyData,
  } = useModel(
    company,
    category,
    measureUnit,
    machineRoom,
    carryingCapacity,
    liftingSpeed,
    advert
  );

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data, advert?.id))}>
      {category.categories ? (
        <>
          <Controller
            name="category"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth error={!!errors.category}>
                {category.categories.length > 0 && (
                  <>
                    {!advert ? (
                      <Modal
                        btnTitle="Категория продукции"
                        background={theme.palette.grey[300]}
                        color={theme.palette.grey[900]}
                      >
                        <PickCategory
                          title="категорию"
                          categories={category.categories}
                          onSelect={(categoryAlias: string) => {
                            setSelectedCategory(categoryAlias);
                            field.onChange(categoryAlias);
                          }}
                        />
                      </Modal>
                    ) : (
                      <TextField
                        disabled
                        label="Категория"
                        defaultValue={capitalizeFirstLetter(
                          advert?.category?.name
                        )}
                      />
                    )}
                    {selectedCategory && selectedCategoryName && (
                      <TextField
                        sx={{ mt: 1.5 }}
                        disabled
                        label="Категория"
                        value={capitalizeFirstLetter(selectedCategoryName)}
                      />
                    )}
                  </>
                )}
                {errors.category && (
                  <FormHelperText>{errors.category?.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
        </>
      ) : (
        <>
          <Controller
            name="category"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth error={!!errors.category}>
                <InputLabel>Категория продукции</InputLabel>
                {categories.length > 0 && (
                  <Select
                    {...field}
                    label="Категория продукции"
                    IconComponent={KeyboardArrowDownIcon}
                    disabled={!!advert}
                    onChange={(event: any) => {
                      field.onChange(event.target.value),
                        setSelectedCategory(event.target.value);
                    }}
                  >
                    {renderItem(categories as any)}
                  </Select>
                )}
                {errors.category && (
                  <FormHelperText>{errors.category?.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
          <Box mt={1.5} />
        </>
      )}
      {!company &&
        !advert &&
        catalogCategories &&
        catalogCategories.length > 0 && (
          <>
            <Box mt={1.5} />
            <Controller
              name="catalogId"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth error={!!errors.catalogId}>
                  <InputLabel>Каталог</InputLabel>
                  {catalogCategories.length > 0 && (
                    <Select
                      {...field}
                      label="Каталог"
                      IconComponent={KeyboardArrowDownIcon}
                      disabled={!!advert}
                      onChange={(event: any) => {
                        field.onChange(event.target.value);
                        setSelectedCatalogId(event.target.value);
                      }}
                    >
                      {catalogCategories.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>
                          {capitalizeFirstLetter(item.name)}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  {errors.catalogId && (
                    <FormHelperText>{errors.catalogId?.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </>
        )}
      <Box mt={1.5} />
      <Box mt={1.5} />
      {company && (
        <Controller
          name="organizationId"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth error={!!errors.organizationId}>
              <InputLabel>Организация</InputLabel>
              {companyData.length > 0 && (
                <Select
                  {...field}
                  label="Организация"
                  IconComponent={KeyboardArrowDownIcon}
                  disabled={!!advert}
                  onChange={(event: any) => {
                    field.onChange(event.target.value);
                    setSelectedCompany(event.target.value);
                  }}
                >
                  {companyData.map((item: any) => (
                    <MenuItem key={item.value} value={item.value}>
                      {capitalizeFirstLetter(item.text)}
                    </MenuItem>
                  ))}
                </Select>
              )}
              {errors.organizationId && (
                <FormHelperText>
                  {errors.organizationId?.message}
                </FormHelperText>
              )}
            </FormControl>
          )}
        />
      )}

      <Box mt={1.5} />
      <Controller
        name="title"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            label="Название"
            placeholder="Введите значение..."
            error={!!errors.title}
            helperText={errors.title?.message}
          />
        )}
      />
      <Box mt={1.5} />

      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            label="Описание"
            placeholder="Введите значение..."
            multiline
            minRows={3}
            error={!!errors.description}
            helperText={errors.description?.message}
          />
        )}
      />
      <Box mt={1.5} />
      <Controller
        name="price"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            label="Цена, руб."
            placeholder="Введите значение..."
            error={!!errors.price}
            helperText={errors.price?.message}
          />
        )}
      />
      <Box mt={1.5} />
      {shouldRenderController() && (
        <>
          <Controller
            name="liftingSpeedId"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth error={!!errors.liftingSpeedId}>
                <InputLabel>Скорость подъема (м/c)</InputLabel>
                {carryingCapacities.length > 0 && (
                  <Select
                    {...field}
                    label="Скорость подъема (м/c)"
                    IconComponent={KeyboardArrowDownIcon}
                    onChange={(event: any) =>
                      field.onChange(event.target.value)
                    }
                  >
                    {liftingSpeeds.map((item: any) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                {errors.liftingSpeedId && (
                  <FormHelperText>
                    {errors.liftingSpeedId?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
          <Box mt={1.5} />
        </>
      )}
      <Box mt={1.5} />
      <Controller
        name="measureUnitId"
        control={control}
        render={({ field }) => (
          <FormControl fullWidth error={!!errors.measureUnitId}>
            <InputLabel>Единица измерения</InputLabel>
            {measure.length > 0 && (
              <Select
                {...field}
                label="Единица измерения"
                IconComponent={KeyboardArrowDownIcon}
                onChange={(event: any) => field.onChange(event.target.value)}
              >
                {measure.map((item: any) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.text}
                  </MenuItem>
                ))}
              </Select>
            )}
            {errors.measureUnitId && (
              <FormHelperText>{errors.measureUnitId?.message}</FormHelperText>
            )}
          </FormControl>
        )}
      />
      <Box mt={1.5} />
      {shouldRenderController() && (
        <>
          <Controller
            name="carryingCapacityId"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth error={!!errors.carryingCapacityId}>
                <InputLabel>Грузоподъемность (кг.)</InputLabel>
                {carryingCapacities.length > 0 && (
                  <Select
                    {...field}
                    label="Грузоподъемность (кг)"
                    IconComponent={KeyboardArrowDownIcon}
                    onChange={(event: any) =>
                      field.onChange(event.target.value)
                    }
                  >
                    {carryingCapacities.map((item: any) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                {errors.carryingCapacityId && (
                  <FormHelperText>
                    {errors.carryingCapacityId?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
          <Box mt={1.5} />
        </>
      )}
      {shouldRenderController() && (
        <>
          <Controller
            name="machineRoomId"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth error={!!errors.machineRoomId}>
                <InputLabel>Машинное помещение</InputLabel>
                {machineRooms.length > 0 && (
                  <Select
                    {...field}
                    label="Машинное помещение"
                    IconComponent={KeyboardArrowDownIcon}
                    onChange={(event: any) =>
                      field.onChange(event.target.value)
                    }
                  >
                    {machineRooms.map((item: any) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                {errors.machineRoomId && (
                  <FormHelperText>
                    {errors.machineRoomId?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
          <Box mt={1.5} />
        </>
      )}
      <Controller
        name="address"
        control={control}
        render={({ field }) => (
          <TextFieldSearch
            field={field}
            error={!!errors.address}
            helperText={errors.address?.message}
          />
        )}
      />

      <Box mt={1.5} />
      {!advert ? (
        <ImagesUpload ref={imagesUploadRef} imageType={imageTypeAlias.advert} />
      ) : (
        <ImagesUpload
          ref={imagesUploadRef}
          advertId={advert.id}
          imageType={imageTypeAlias.advert}
        />
      )}
      <Box mt={1.5} />
      <Button
        size="small"
        type="submit"
        variant="contained"
        disabled={loading}
        sx={{
          width: '50%',
          mb: 2,
          borderRadius: 0,
          '@media (max-width: 960px)': { width: '100%' },
        }}
      >
        {loading ? (
          <CircularProgress size={25} />
        ) : !advert ? (
          'Сохранить'
        ) : (
          'Сохранить изменения'
        )}
      </Button>
    </form>
  );
};
