import { Advert } from 'shared/services/interfaces/Advert';
import { EquipmentForm } from 'features/Advert/EquipmentForm';
import { SpecialMachineryForm } from 'features/Advert/SpecialMachineryForm';
import { BuildingMaterialForm } from 'features/Advert/BuildingMaterialForm';
import { ProductForm } from 'features/Advert/ProductForm';
import { WorkService } from 'features/Advert/WorkService';
import { Realty } from 'features/Advert/RealtyForm';
import { Category } from 'services/interfaces/Category';
import { Measure } from 'services/interfaces/Measure';
import { CatalogCategory } from 'types/types';

export const categoryAdvertForm = (
  category: Category,
  measureUnit: Measure[],
  catalogCategories?: CatalogCategory[],
  advert?: Advert,
  machineRoom?: any,
  carryingCapacity?: any,
  liftingSpeed?: any,
  company?: any
) => {
  switch (category.alias) {
    case 'specialMachinery':
      return (
        <SpecialMachineryForm
          company={company}
          category={category}
          measureUnit={measureUnit}
          advert={advert}
          catalogCategories={catalogCategories}
        />
      );
    case 'equipment':
      return (
        <EquipmentForm
          company={company}
          category={category}
          measureUnit={measureUnit}
          machineRoom={machineRoom}
          carryingCapacity={carryingCapacity}
          liftingSpeed={liftingSpeed}
          advert={advert}
          catalogCategories={catalogCategories}
        />
      );
    case 'buildingMaterial':
      return (
        <BuildingMaterialForm
          company={company}
          category={category}
          measureUnit={measureUnit}
          advert={advert}
          catalogCategories={catalogCategories}
        />
      );
    case 'product':
      return (
        <ProductForm
          company={company}
          category={category}
          measureUnit={measureUnit}
          advert={advert}
          catalogCategories={catalogCategories}
        />
      );
    case 'workService':
      return (
        <WorkService
          company={company}
          category={category}
          measureUnit={measureUnit}
          advert={advert}
          catalogCategories={catalogCategories}
        />
      );
    case 'realty':
      return <Realty />;
    default:
      return null;
  }
};
