/* eslint-disable @next/next/no-img-element */
import {
  ChangeEvent,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import WebApi from '../../shared/services';
import { Session } from 'next-auth';
import { useSession } from 'next-auth/react';
import { ImageTypeAlias } from 'shared/constants';
import { Image } from 'shared/types/Image';
import { fileCategory } from 'shared/constants';
import { FileResponse } from 'shared/services/Files';
import { Box, Button, Grid, Typography } from '@mui/material';

type ImageUploadProps = Partial<{
  alt: string;
  userId: number;
  imgStyle: React.CSSProperties;
  isCreate: boolean;
  labelStyle: React.CSSProperties;
  imageType: ImageTypeAlias;
  organizationId: number;
  catalogId: number;
  text: string;
}>;

type ImageFile = {
  file?: Blob;
  image: Partial<Image>;
};

type SendImgArgs = {
  imageFile: ImageFile;
  session: Session | null;
};

const NEXT_PUBLIC_IMG = process.env.NEXT_PUBLIC_IMG;

export const sendImg = async (
  args: SendImgArgs
): Promise<FileResponse<Image>> => {
  const { session, imageFile } = args;
  const { image, file } = imageFile;
  if (image && file) {
    const body = new FormData();
    body.append('file', file);
    body.append('image', JSON.stringify(image));
    const resp = image.id
      ? await WebApi(session).Files.updateFile<Image>(
          `${fileCategory.image}s`,
          body
        )
      : await WebApi(session).Files.createFile<Image>(
          `${fileCategory.image}s`,
          body
        );
    return resp;
  }
  return {} as FileResponse<Image>;
};

export const ImageUpload = forwardRef(
  (
    {
      alt,
      userId,
      imgStyle,
      isCreate,
      imageType,
      labelStyle,
      organizationId,
      catalogId,
      text,
    }: ImageUploadProps,
    ref
  ) => {
    ImageUpload.displayName = 'ImageUpload';
    const [image, setImage] = useState<Image>();
    const [file, setFile] = useState<Blob>();
    const { data: session } = useSession();

    useImperativeHandle(ref, () => ({
      sendImgHandler: async (
        args: SendImgArgs
      ): Promise<ReturnType<typeof sendImg>> => {
        const { imageFile, session } = args;
        const { image } = imageFile;
        const { userId, imageType, organizationId, catalogId } = image;
        const isParams = imageType && (organizationId || userId || catalogId);
        if (file && isParams) {
          return sendImg({
            session,
            imageFile: {
              file,
              image: {
                id: image?.id,
                userId,
                imageType,
                organizationId,
                catalogId,
                filename: file.name,
              },
            },
          });
        }
        return {} as Promise<ReturnType<typeof sendImg>>;
      },
    }));

    useEffect(() => {
      const isParams = imageType && (organizationId || userId || catalogId);
      if (!isCreate && isParams) {
        const getImg = async () => {
          const args: Partial<Image> = {};
          if (userId) {
            args.userId = userId;
          }
          if (imageType) {
            args.imageType = imageType;
          }
          if (organizationId) {
            args.organizationId = organizationId;
          }
          if (catalogId) {
            args.catalogId = catalogId;
          }
          const { data } = await WebApi(session).Image.getImage(args);
          setImage!(data?.length ? data[data.length - 1] : null);
        };
        getImg();
      }
    }, [organizationId, catalogId]);

    const imgId = 'image-upload-' + imageType;
    const inputId = 'input-upload-' + imageType;

    function showImage(event: ChangeEvent<HTMLInputElement>) {
      const imagElt = document.getElementById(imgId) as HTMLImageElement;
      if (imagElt && event.target.files?.length) {
        const reader = new FileReader();
        reader.onload = function () {
          if (reader.result) {
            imagElt.src = reader.result as string;
          }
        };
        reader.readAsDataURL(event.target.files[0]);
        setFile(event.target.files[0]);
      }
    }
    const src = image?.filepath
      ? `${NEXT_PUBLIC_IMG}/${image?.filepath}`
      : 'https://react.semantic-ui.com/images/wireframe/image.png';
    const img: React.ReactElement = (
      <>
        <Grid container spacing={2} sx={{ alignItems: 'center' }}>
          <Grid item lg={4} md={5} sm={6} xs={4} sx={{ flexBasis: '50%' }}>
            <img id={imgId} style={imgStyle} src={src} alt={alt} />
          </Grid>
          <Grid item lg={4} md={5} sm={6} xs={6} sx={{ flexBasis: '50%' }}>
            <Typography variant="body2">{text}</Typography>
            <label htmlFor={inputId} style={labelStyle}>
              <Button
                size="small"
                sx={{
                  width: '100%',
                  mt: '1rem',
                }}
                onClick={() => document.getElementById(inputId)?.click()}
              >
                Загрузить файл
              </Button>
              <input
                type="file"
                accept="image/*"
                id={inputId}
                style={{ display: 'none' }}
                onChange={showImage}
              />
            </label>
          </Grid>
        </Grid>
      </>
    );
    return img;
  }
);
