import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';

interface Film {
  title: string;
  year: number;
}

export const UI = ({ error, helperText, field, disabled }: any) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly Film[]>([]);
  const [loading, setLoading] = React.useState(false);
  const getCoordinates = async (value: string) => {
    if (value) {
      const data = await axios.get(
        `https://geocode-maps.yandex.ru/1.x/?apikey=${process.env.NEXT_PUBLIC_YANDEX_APIKEY}&geocode=${value}&format=json`
      );
      setOptions(data.data.response.GeoObjectCollection.featureMember);
    }
  };

  return (
    <Autocomplete
      fullWidth
      open={open}
      autoComplete="off"
      {...field}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      filterOptions={(options, state) => options}
      getOptionLabel={(option: any) => {
        return (
          option?.GeoObject?.metaDataProperty.GeocoderMetaData.Address
            .formatted ?? field.value
        );
      }}
      renderOption={(props, option: any) => (
        <li
          {...props}
          style={{
            padding: '10px',
            borderBottom: '1px solid #ccc', // Добавляем нижнюю границу
            margin: '5px 0', // Добавляем отступы сверху и снизу для разделения элементов
          }}
        >
          {option.GeoObject.metaDataProperty.GeocoderMetaData.Address.formatted}
        </li>
      )}
      noOptionsText={'Введите адрес'}
      options={options}
      disabled={disabled}
      loading={loading}
      onChange={(event, value) => {
        field.onChange(value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Адрес"
          fullWidth
          onChange={(e: any) => {
            getCoordinates(e.target.value as string);
          }}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
