/* eslint-disable @next/next/no-img-element */
import React from 'react';
import {
  ChangeEvent,
  useState,
  forwardRef,
  useImperativeHandle,
  KeyboardEvent,
  useRef,
  useEffect,
} from 'react';
import WebApi from '../../shared/services';
import { Session } from 'next-auth';
import { useSession } from 'next-auth/react';
import { eventKey } from 'shared/constants/EventKey';
import { Image } from 'shared/types/Image';
import {
  Button,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import { fileCategory } from 'shared/constants';
import { RequestConfig } from 'shared/types/WebApiTypes';
import { GetParameters } from 'shared/services/Files';
import theme from 'shared/theme';

type ImageUploadProps = Partial<Image> & Partial<{}>;

export type ImageData = Partial<{
  image: Partial<Image>;
  src: string;
  file: File;
  filestr: string;
  isFile: boolean;
  isImage: boolean;
  del: boolean;
}>;

type SendImagesArgs = {
  session: Session | null;
  imagesData: ImageData[];
};

type DelImagesArgs = {
  ids: number[];
  session: Session | null;
};

const NEXT_PUBLIC_IMG = process.env.NEXT_PUBLIC_IMG;

export async function getImages<R extends Image | Image[]>(
  session: Session | null,
  args: GetParameters<Image>
) {
  return WebApi(session).Files.getFile<Image, R>(
    `${fileCategory.image}s`,
    args
  );
}

export const delImages = async (args: DelImagesArgs) => {
  const { ids, session } = args;
  const resp = await WebApi(session).Image.delImage({ ids });
  return resp;
};

export const sendImages = async (
  args: SendImagesArgs,
  config?: RequestConfig
) => {
  const { session, imagesData } = args;
  const body = new FormData();
  const images: Partial<Image>[] = [];
  for (let i = 0; i < imagesData.length; i++) {
    const image = imagesData[i].image;
    const file = imagesData[i].file;
    if (image && file) {
      images.push(image);
      body.append('files', file);
    }
  }
  body.append('images', JSON.stringify(images));
  return WebApi(session).Files.createFile<Image[]>(
    `${fileCategory.image}s`,
    body,
    config
  );
};

export const ImagesUpload = forwardRef(
  (
    {
      imageType,
      advertId,
      tenderId,
      organizationId,
      catalogId,
      docId,
    }: ImageUploadProps,
    ref
  ) => {
    const { data: session } = useSession();
    const [imagesData, setImagesData] = useState<ImageData[]>([]);
    ImagesUpload.displayName = 'ImagesUpload';

    useImperativeHandle(ref, () => ({
      sendImagesHandler: async (args: Partial<Image>) => {
        if (imagesData.length) {
          const { advertId, catalogId, docId } = args;
          const imgDat = imagesData.filter((v) => v.file);
          for (let i = 0; i < imgDat.length; i++) {
            if (advertId) {
              imgDat[i].image!.advertId = advertId;
            }
            if (catalogId) {
              imgDat[i].image!.catalogId = catalogId;
            }
            if (docId) {
              imgDat[i].image!.docId = docId;
            }
          }
          const delImageIds = imagesData
            .map((v) => (v.isImage && v.del ? v.image?.id : null))
            .filter(Boolean) as number[];
          const promises = [];
          if (imgDat.length) {
            promises.push(sendImages({ imagesData: imgDat, session }));
          }
          if (delImageIds.length) {
            promises.push(delImages({ ids: delImageIds, session }));
          }
          return Promise.all(promises);
        }
        return [];
      },
    }));

    useEffect(() => {
      const isParams = advertId;
      if (isParams) {
        getImages<Image[]>(session, {
          advertId,
          imageType,
        }).then((resp) => {
          const images = resp?.data;
          imagesData.splice(0, imagesData.length);
          if (images?.length) {
            for (let i = 0; i < images.length; i++) {
              imagesData.push({
                del: false,
                src: `${NEXT_PUBLIC_IMG}${images[i].filepath}`,
                isImage: true,
                image: images[i],
              });
            }
            setImagesData([...imagesData]);
          }
          return resp;
        });
      }
    }, [advertId]);

    useEffect(() => {
      const isParams = catalogId;
      if (isParams) {
        getImages<Image[]>(session, {
          catalogId,
          imageType,
        }).then((resp) => {
          const images = resp?.data;
          imagesData.splice(0, imagesData.length);
          if (images?.length) {
            for (let i = 0; i < images.length; i++) {
              imagesData.push({
                del: false,
                src: `${NEXT_PUBLIC_IMG}${images[i].filepath}`,
                isImage: true,
                image: images[i],
              });
            }
            setImagesData([...imagesData]);
          }
          return resp;
        });
      }
    }, [catalogId]);

    useEffect(() => {
      const isParams = docId;
      if (isParams) {
        getImages<Image[]>(session, {
          docId,
          imageType,
        }).then((resp) => {
          const images = resp?.data;
          imagesData.splice(0, imagesData.length);
          if (images?.length) {
            for (let i = 0; i < images.length; i++) {
              imagesData.push({
                del: false,
                src: `${NEXT_PUBLIC_IMG}${images[i].filepath}`,
                isImage: true,
                image: images[i],
              });
            }
            setImagesData([...imagesData]);
          }
          return resp;
        });
      }
    }, [docId]);

    function addImage(event: ChangeEvent<HTMLInputElement>) {
      if (event.target.files?.length) {
        const file = event.target.files[0];
        const userId = session?.user?.id;
        const reader = new FileReader();
        reader.onload = function () {
          if (reader.result) {
            const filestr = reader.result as string;
            const found = imagesData.find((v) => v.filestr === filestr);
            if (!found) {
              imagesData.push({
                del: false,
                file,
                filestr,
                isFile: true,
                image: {
                  userId,
                  imageType,
                  advertId,
                  tenderId,
                  organizationId,
                  catalogId,
                  docId,
                  filename: file.name,
                },
              });
              setImagesData([...imagesData]);
            }
          }
        };
        reader.readAsDataURL(file);
      }
    }

    function delImage(i: number) {
      if (imagesData[i].isImage) {
        imagesData[i].del = true;
      } else if (imagesData[i].isFile) {
        imagesData.splice(i, 1);
      }
      setImagesData([...imagesData]);
    }

    const inputElement = useRef<HTMLInputElement>(null);
    function handleKeyDown(event: KeyboardEvent<HTMLElement>) {
      if (event.key === eventKey.enter) {
        inputElement.current?.click();
      }
    }

    return (
      <>
        <Button
          size="small"
          sx={{
            width: '50%',
            // p: 1.5,
            borderRadius: 0,
            background: theme.palette.grey[300],
            color: theme.palette.grey[900],
            '@media (max-width: 960px)': {
              width: '100%',
            },
          }}
          variant="contained"
          component="label"
          onKeyDown={handleKeyDown}
        >
          Добавить изображение
          <input
            hidden
            type="file"
            accept="image/*"
            id="images-upload-input"
            ref={inputElement}
            onChange={addImage}
          />
        </Button>
        <ImageList cols={3} gap={8}>
          {imagesData?.map((imd, i) =>
            imd.del ? (
              ''
            ) : (
              <ImageListItem key={'image-' + i}>
                <img
                  style={{ maxWidth: '293px', maxHeight: '293px' }}
                  src={imd.src || imd.filestr}
                  alt="Advert Image"
                />
                <ImageListItemBar
                  sx={{
                    background:
                      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                  }}
                  position="top"
                  actionPosition="right"
                  actionIcon={
                    <IconButton
                      onClick={() => delImage(i)}
                      sx={{ color: 'white' }}
                    >
                      <Close />
                    </IconButton>
                  }
                />
              </ImageListItem>
            )
          )}
        </ImageList>
      </>
    );
  }
);
